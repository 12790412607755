import { capsCount, numberCount, smallCount, symbolCount } from "../../shared/password";
import styles from "./Instructions.module.css";

const getSign = (ok) => {
	return ok ? <span className={styles.success}>✔</span> : <span className={styles.error}>✘</span>;
};

const Instructions = ({ password }) => {
	return (
		<div className={styles.instruction}>
			Das Passwort muss mindestens 12 Zeichen
			{getSign(password.length >= 12)}
			haben, bestehend aus grossen
			{getSign(capsCount(password))}
			und kleinen
			{getSign(smallCount(password))}
			Buchstaben, mindestens einer Zahl
			{getSign(numberCount(password))}
			und einem Sonderzeichen (@$!%*?&...)
			{getSign(symbolCount(password))}
		</div>
	);
};

export default Instructions;

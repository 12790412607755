import { Fragment } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import Aerztefon from "../assets/images/Aerztefon.png";
import { authenticated } from "../features/auth/authSlice";

import * as routes from "../shared/routes";

const AerztefonLogo = () => {
	const isAuthenticated = useSelector(authenticated);
	const application = useSelector((state) => state.application);
	const navigate = useNavigate();

	return (
		<div id="aerztefonLogo">
			<img src={Aerztefon} alt="Aerztefon" />
			<span>
				{isAuthenticated && application.user && (
					<Fragment>
						{application.user.name}{" "}
						<span className="click" onClick={() => navigate(routes.LOGOUT)}>
							abmelden
						</span>{" "}
						•{" "}
					</Fragment>
				)}
				V{application.version}
			</span>
		</div>
	);
};

export default AerztefonLogo;

import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Instructions from "../../components/Password/Instructions";
import { resetPassword } from "../../features/auth/authSlice";
import Panel from "../../hoc/Panel/Panel";
import { getPasswordError } from "../../shared/password";

import * as routes from "../../shared/routes";

import styles from "./ResetPassword.module.css";

const ResetPassword = () => {
	const dispatch = useDispatch();
	const [data, setData] = useState({
		password: "",
		confirmPassword: ""
	});
	const [error, setError] = useState("");
	const { resetPasswordKey } = useParams();
	const navigate = useNavigate();

	if (!resetPasswordKey) {
		navigate(routes.LOGIN);
	}

	const save = () => {
		const passwordError = getPasswordError(data.password, data.confirmPassword);
		if (passwordError) {
			setError(passwordError);
			return;
		}
		dispatch(resetPassword({ resetPasswordKey, password: data.password, confirmPassword: data.confirmPassword }));
	};

	const change = (e) => {
		const fieldname = e.target.name;
		const value = e.target.value;
		setData((prev) => ({ ...prev, [fieldname]: value }));
		setError("");
	};

	return (
		<Panel title="Neues Passwort" size="small">
			{error && <div className="error">{error}</div>}
			<div className={styles.content}>
				{!error && <div>Bitte erstelle Dir ein neues Passwort.</div>}

				<label>
					<span>Passwort</span>
					<input type="password" name="password" value={data.password} onChange={change} />
				</label>
				<label>
					<span>Passwort (Bestätigung)</span>
					<input type="password" name="confirmPassword" value={data.confirmPassword} onChange={change} />
				</label>
				<Instructions password={data.password} />
				<div className={styles.buttons}>
					<button type="button" onClick={save}>
						Speichern
					</button>
				</div>
			</div>
		</Panel>
	);
};

export default ResetPassword;

import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Instructions from "../../components/Password/Instructions";
import { savePassword } from "../../features/auth/authSlice";
import Panel from "../../hoc/Panel/Panel";
import { getPasswordError } from "../../shared/password";
import styles from "./NewPassword.module.css";

const NewPassword = () => {
	const dispatch = useDispatch();
	const userId = useSelector((state) => state.application.user?.id);
	const [data, setData] = useState({
		password: "",
		confirmPassword: ""
	});
	const [error, setError] = useState("");

	if (!userId) {
		return null;
	}

	const save = () => {
		const passwordError = getPasswordError(data.password, data.confirmPassword);
		if (passwordError) {
			setError(passwordError);
			return;
		}
		dispatch(savePassword({ id: userId, data: { password: data.password, confirmPassword: data.confirmPassword } }));
	};

	const change = (e) => {
		const fieldname = e.target.name;
		const value = e.target.value;
		setData((prev) => ({ ...prev, [fieldname]: value }));
		setError("");
	};

	return (
		<Panel title="Neues Passwort" size="small">
			{error && <div className="error">{error}</div>}
			<div className={styles.content}>
				{!error && <div>Bitte erstelle Dir ein neues Passwort.</div>}

				<label>
					<span>Passwort</span>
					<input type="password" name="password" value={data.password} onChange={change} />
				</label>
				<label>
					<span>Passwort (Bestätigung)</span>
					<input type="password" name="confirmPassword" value={data.confirmPassword} onChange={change} />
				</label>
				<Instructions password={data.password} />
				<div className={styles.buttons}>
					<button type="button" onClick={save}>
						Speichern
					</button>
				</div>
			</div>
		</Panel>
	);
};

export default NewPassword;

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../shared/axios";
import { logout } from "../auth/authSlice";

const namespace = "application";

const initialState = {
	user: null,
	version: "1.0.1",
	webAppMenu: null,
	redirect: null
};

export const getApplicationData = createAsyncThunk(`${namespace}/getApplicationData`, async (_, { dispatch, rejectWithValue }) => {
	try {
		const { data } = await axios.get("application");
		return data;
	} catch (error) {
		return rejectWithValue(error.response.status);
	}
});

const applicationSlice = createSlice({
	name: namespace,
	initialState,
	reducers: {
		setRedirect: (state, { payload }) => {
			state.redirect = payload;
		},
		clearRedirect: (state, action) => {
			state.redirect = null;
		}
	},
	extraReducers: (builder) => {
		builder
			.addCase(getApplicationData.fulfilled, (state, { payload }) => {
				state.user = payload.user;
				state.version = payload.version;
				state.webAppMenu = payload.webAppMenu;
			})
			.addCase(logout.fulfilled, (state, { payload }) => {
				return initialState;
			});
	}
});

export const { setRedirect, clearRedirect } = applicationSlice.actions;

export default applicationSlice.reducer;

import { useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";

import { login } from "../../features/auth/authSlice";

import Panel from "../../hoc/Panel/Panel";
import Error from "../../ui/Error";

import * as routes from "../../shared/routes";
import styles from "./Login.module.css";

const Login = () => {
	const [eMail, setEMail] = useState("");
	const [password, setPassword] = useState("");

	const dispatch = useDispatch();

	const changeEMail = (event) => {
		setEMail(event.target.value);
	};

	const changePasswort = (event) => {
		setPassword(event.target.value);
	};

	const handleLogin = (event) => {
		event.preventDefault();
		dispatch(login({ eMail, password }));
	};
	return (
		<Panel title="Anmelden" size="small">
			<Error />
			<form className={styles.login} onSubmit={handleLogin}>
				<label>
					<span>E-Mail</span>
					<input type="text" autoComplete="username" value={eMail} onChange={changeEMail} />
				</label>
				<label>
					<span>Passwort</span>
					<input type="password" autoComplete="current-password" value={password} onChange={changePasswort} />
				</label>
				<div className={styles.buttons}>
					<button type="submit">Anmelden</button>
				</div>
				<div>
					Ich habe mein <Link to={routes.PASSWORD_FORGOTTEN}>Passwort vergessen</Link>
				</div>
			</form>
		</Panel>
	);
};

export default Login;

import { useSelector } from "react-redux";
import TeaserButton from "../../components/TeaserButton/TeaserButton";
import Panel from "../../hoc/Panel/Panel";

import styles from "./Home.module.css";

const Home = () => {
	const webAppMenu = useSelector((state) => state.application.webAppMenu);

	if (!webAppMenu) {
		return null;
	}

	return (
		<Panel title="Applikationen" size="large">
			<div>
				<div className={styles.teaserButtons}>
					{webAppMenu
						.filter((webApp) => !webApp.active)
						.map((webApp) => (
							<TeaserButton key={webApp.url} name={webApp.name} url={webApp.url} icon={webApp.icon} />
						))}
				</div>
			</div>
		</Panel>
	);
};

export default Home;

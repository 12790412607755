import { useSelector } from "react-redux";
import { authenticated } from "../../features/auth/authSlice";
import WebAppMenu from "./WebAppMenu/WebAppMenu";

import { ReactComponent as ArrowDown } from "../../assets/images/arrowDown.svg";
import { Link } from "react-router-dom";

import * as routes from "../../shared/routes";

const Menu = () => {
	const isAuthenticated = useSelector(authenticated);
	const name = useSelector((state) => state.application.user?.name);
	const requireNewPassword = useSelector((state) => state.auth.requireNewPassword);
	const webAppMenu = useSelector((state) => state.application.webAppMenu);

	if (!isAuthenticated || requireNewPassword) {
		return null;
	}

	return (
		<ul id="menu">
			<WebAppMenu menuItems={webAppMenu} />
			{isAuthenticated && (
				<li className="parent">
					<span className={false ? "active" : ""}>
						<ArrowDown className="menuArrow" />
						{name}
					</span>
					<ul>
						<li>
							<Link to={routes.CHANGE_PASSWORD}>Passwort ändern</Link>
						</li>
					</ul>
				</li>
			)}
		</ul>
	);
};

export default Menu;

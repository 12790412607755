import axios from "axios";

let refreshingToken = null;

const instance = axios.create({
	baseURL: process.env.REACT_APP_AXIOS_URL,
	withCredentials: true
});

const getRefreshToken = () => {
	return axios.post(process.env.REACT_APP_AXIOS_URL + "refreshToken", {}, { withCredentials: true });
};

instance.interceptors.response.use(
	(response) => {
		return response;
	},
	async (error) => {
		const originalRequest = error.config;
		if (process.env.REACT_APP_ENV === "development") {
			console.groupCollapsed("instance.interceptors.response");
			console.log(originalRequest);
		}
		if (originalRequest.url !== "login" && error.response.status === 401 && !originalRequest._retry) {
			originalRequest._retry = true;
			try {
				process.env.REACT_APP_ENV === "development" && console.log("Getting a new token with the refresh token");
				refreshingToken = refreshingToken ? refreshingToken : getRefreshToken();
				const result = await refreshingToken;
				refreshingToken = null;
				if (process.env.REACT_APP_ENV === "development") {
					console.log("Juhheee, new TOKEN");
					console.log(result);
					console.groupEnd();
				}
				return instance(originalRequest);
			} catch (err) {
				return Promise.reject(err);
			}
		}
		console.groupEnd();
		// return Error object with Promise
		return Promise.reject(error);
	}
);

export default instance;

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../shared/axios";
import * as routes from "../../shared/routes";
import { setRedirect } from "../application/applicationSlice";

import { setError, clearAllMessages, setTempSuccess, setTempError } from "../message/messageSlice";

const isUnauthorized = (action) => {
	return action.type.endsWith("rejected") && action.payload === 401;
};

const initialState = {
	authenticated: false,
	autoSignupTried: false,
	forwardTo: null,
	requireNewPassword: false
};

const namespace = "auth";

export const authenticated = (state) => state.auth.authenticated;

export const login = createAsyncThunk(`${namespace}/login`, async (authData, { dispatch, rejectWithValue }) => {
	dispatch(clearAllMessages());
	try {
		const { data } = await axios.post("login", authData);
		return data;
	} catch (error) {
		if (error.response.status === 401) {
			dispatch(setError("Die E-Mail Adresse oder das Passwort ist falsch."));
		}
		return rejectWithValue(error.response.status);
	}
});

export const tryAutoSignUp = createAsyncThunk(`${namespace}/tryAutoSignUp`, async (forwardTo, { dispatch, rejectWithValue }) => {
	try {
		forwardTo && dispatch(setForwardTo(forwardTo));
		const { data } = await axios.get("autoSignup");
		return data;
	} catch (error) {
		return rejectWithValue(error.response.status);
	}
});

export const logout = createAsyncThunk(`${namespace}/logout`, async (_, { dispatch, rejectWithValue }) => {
	dispatch(clearAllMessages());
	try {
		const { data } = await axios.get("logout");
		dispatch(setTempSuccess("Du hast Dich erfolgreich abgemeldet"));
		return data;
	} catch (error) {
		return rejectWithValue(error.response.status);
	}
});

export const savePassword = createAsyncThunk(`${namespace}/savePassword`, async (payload, { dispatch, rejectWithValue }) => {
	try {
		const { data } = await axios.patch("password/" + payload.id, payload.data);
		dispatch(setTempSuccess("Dein Passwort wurde erfolgreich gespeichert"));
		return data;
	} catch (error) {
		return rejectWithValue(error.response.status);
	}
});

export const passwordForgotten = createAsyncThunk(`${namespace}/passwordForgotten`, async (payload, { dispatch, rejectWithValue }) => {
	try {
		const { data } = await axios.post("passwordForgotten", payload);
		dispatch(setRedirect(routes.HOME));
		dispatch(setTempSuccess("Wir haben Dir eine E-Mail mit Instruktionen zur Rücksetzung Deines Passwortes gesendet"));
		return data;
	} catch (error) {
		switch (error.response.status) {
			case 406:
				dispatch(setRedirect(routes.HOME));
				dispatch(setTempError("Es wurde Dir bereits eine E-Mail mit Instruktionen zur Rücksetzung Deines Passwortes gesendet"));
				break;

			default:
		}

		return rejectWithValue(error.response.status);
	}
});

export const updatePassword = createAsyncThunk(`${namespace}/updatePassword`, async (payload, { dispatch, rejectWithValue }) => {
	try {
		const { data } = await axios.patch("password/change/" + payload.id, payload.data);
		dispatch(setRedirect(routes.HOME));
		dispatch(setTempSuccess("Dein Passwort wurde erfolgreich geändert"));
		return data;
	} catch (error) {
		switch (error.response.status) {
			case 406:
				dispatch(setTempError("Dein aktuelles Passwort ist nicht korrekt"));
				break;

			default:
		}

		return rejectWithValue(error.response.status);
	}
});

export const resetPassword = createAsyncThunk(`${namespace}/resetPassword`, async (payload, { dispatch, rejectWithValue }) => {
	try {
		const { data } = await axios.post("resetPassword", payload);
		dispatch(setRedirect(routes.HOME));
		dispatch(setTempSuccess("Dein neues Passwort wurde erfolgreich gespeichert"));
		return data;
	} catch (error) {
		switch (error.response.status) {
			case 406:
				dispatch(setRedirect(routes.HOME));
				dispatch(setTempError("Der Link zur Eingabe Deines neuen Passwortes ist nicht mehr gültig"));
				break;

			default:
				dispatch(setRedirect(routes.HOME));
				dispatch(setTempError("Es ist ein Fehler aufgetreten"));
		}

		return rejectWithValue(error.response.status);
	}
});

const authSlice = createSlice({
	name: namespace,
	initialState,
	reducers: {
		setForwardTo: (state, action) => {
			state.forwardTo = action.payload;
		},
		forwardTo: (state, action) => {
			if (state.forwardTo) {
				window.location.href = state.forwardTo;
			}
		}
	},
	extraReducers: (builder) => {
		builder
			.addCase(login.fulfilled, (state, { payload }) => {
				state.authenticated = true;
				state.requireNewPassword = payload.requireNewPassword;
			})
			.addCase(logout.fulfilled, (state, { payload }) => {
				state.authenticated = false;
			})
			.addCase(tryAutoSignUp.fulfilled, (state, { payload }) => {
				state.authenticated = true;
				state.autoSignupTried = true;
			})
			.addCase(tryAutoSignUp.rejected, (state, { payload }) => {
				state.autoSignupTried = true;
			})
			.addMatcher(isUnauthorized, (state, { payload }) => {
				state.authenticated = false;
			});
	}
});

export const { setForwardTo, forwardTo, clearRefreshNote } = authSlice.actions;

export default authSlice.reducer;

import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, Route, Routes, useNavigate, useSearchParams } from "react-router-dom";

import { authenticated, forwardTo, tryAutoSignUp } from "./features/auth/authSlice";
import { clearRedirect } from "./features/application/applicationSlice";
import * as routes from "./shared/routes";

import Login from "./containers/Login/Login";
import Home from "./containers/Home/Home";
import NewPassword from "./containers/NewPassword/NewPassword";
import Layout from "./hoc/Layout/Layout";
import RequireAuth from "./components/RequireAuth/RequireAuth";
import RequireNoAuth from "./components/RequireNoAuth/RequireNoAuth";
import Logout from "./containers/Logout/Logout";
import ChangePassword from "./containers/ChangePassword/ChangePassword";
import PasswordForgotten from "./containers/PasswordForgotten/PasswordForgotten";
import ResetPassword from "./containers/ResetPassword/ResetPassword";

const App = () => {
	const isAuthenticated = useSelector(authenticated);
	const autoSignupTried = useSelector((state) => state.auth.autoSignupTried);
	const requireNewPassword = useSelector((state) => state.auth.requireNewPassword);
	const forward = useSelector((state) => state.auth.forwardTo);
	const redirect = useSelector((state) => state.application.redirect);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();
	const calledURL = searchParams.get("forwardTo");

	useEffect(() => {
		if (!autoSignupTried) {
			dispatch(tryAutoSignUp(calledURL));
		}
	}, [autoSignupTried, calledURL, dispatch]);

	useEffect(() => {
		if (isAuthenticated && !requireNewPassword && forward) {
			dispatch(forwardTo());
		}
	}, [isAuthenticated, requireNewPassword, forward, dispatch, navigate]);

	useEffect(() => {
		if (isAuthenticated && requireNewPassword) {
			navigate(routes.NEW_PASSWORD);
		}
	}, [isAuthenticated, requireNewPassword, navigate]);

	useEffect(() => {
		if (redirect) {
			navigate(redirect);
			dispatch(clearRedirect());
		}
	}, [redirect, dispatch, navigate]);

	if (!autoSignupTried) {
		return null;
	}

	return (
		<Routes>
			<Route element={<Layout />}>
				<Route element={<RequireAuth />}>
					<Route path={routes.LOGOUT} element={<Logout />} />
					<Route path={routes.CHANGE_PASSWORD} element={<ChangePassword />} />
					{requireNewPassword ? <Route path={routes.NEW_PASSWORD} element={<NewPassword />} /> : <Route path="/" element={<Home />} />}
					<Route path="*" element={<Navigate to={requireNewPassword ? routes.NEW_PASSWORD : "/"} replace />} />
				</Route>
				<Route element={<RequireNoAuth />}>
					<Route path={routes.PASSWORD_FORGOTTEN + "/:resetPasswordKey"} element={<ResetPassword />} />
					<Route path={routes.PASSWORD_FORGOTTEN} element={<PasswordForgotten />} />
					<Route path={routes.LOGIN} element={<Login />} />
					<Route path="*" element={<Navigate to={routes.LOGIN} replace />} />
				</Route>
			</Route>
		</Routes>
	);
};

export default App;

import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { passwordForgotten } from "../../features/auth/authSlice";
import Panel from "../../hoc/Panel/Panel";
import styles from "./PasswordForgotten.module.css";

import { validateEmail } from "../../shared/utility";
import * as routes from "../../shared/routes";

const PasswordForgotten = () => {
	const dispatch = useDispatch();
	const [eMail, setEMail] = useState("");
	const [error, setError] = useState(false);
	const navigate = useNavigate();

	const save = () => {
		dispatch(passwordForgotten({ eMail }));
	};

	const change = (e) => {
		const value = e.target.value;
		setEMail(value);
		setError(!validateEmail(value));
	};

	const cancel = () => {
		navigate(routes.LOGIN);
	};

	return (
		<Panel title="Passwort vergessen" size="small">
			<div className={styles.content}>
				<div>Bitte gib Deine bei uns registrierte E-Mail Adresse an. Du bekommst anschliessend eine E-Mail mit Instruktionen zur Rücksetzung Deines Passwortes.</div>

				<label>
					<span>Deine E-Mail Adresse</span>
					<input type="text" name="eMail" className={error ? styles.error : ""} value={eMail} onChange={change} />
				</label>
				<div className={styles.buttons}>
					<button type="button" onClick={cancel}>
						Abbrechen
					</button>
					<button type="button" onClick={save} disabled={!eMail.length || error}>
						Absenden
					</button>
				</div>
			</div>
		</Panel>
	);
};

export default PasswordForgotten;
